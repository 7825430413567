.grid-container-reg {
    column-gap: 10px;
    display: grid;
    justify-content: center;
    align-self: center; 
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 992px){
    .grid-container-reg {
        column-gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 768px){
    .grid-container-reg {
        column-gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 576px){
    .grid-container-reg {
        column-gap: 10px;
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media (max-width: 360px){
    .grid-container-reg {
        column-gap: 10px;
        display: grid;
        grid-template-columns: 1fr;
    }
}

#container_form_row{
    column-gap: 10px;
    display: grid;
    justify-content: center;
    align-self: center; 
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 992px){
    #container_form_row{
        column-gap: 10px;
        display: grid;
        justify-content: center;
        align-self: center; 
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px){
    #container_form_row{
        column-gap: 10px;
        display: grid;
        justify-content: center;
        align-self: center; 
        grid-template-columns: 1fr;
    }
}

@media (max-width: 576px){
    #container_form_row{
        column-gap: 10px;
        display: grid;
        justify-content: center;
        align-self: center; 
        grid-template-columns: 1fr;
    }
}

@media (max-width: 360px){
    #container_form_row{
        column-gap: 10px;
        display: grid;
        justify-content: center;
        align-self: center; 
        grid-template-columns: 1fr;
    }
}

#subtitlenya{
    font-size: 14pt;
    color: rgb(82, 82, 82);
    padding-bottom: 30px;
}

@media (max-width: 992px){
    #subtitlenya{
        font-size: 13pt;
        color: rgb(82, 82, 82);
        padding-bottom: 30px;
    }
}

@media (max-width: 768px){
    #subtitlenya{
        font-size: 13pt;
        color: rgb(82, 82, 82);
        padding-bottom: 30px;
    }
}

@media (max-width: 576px){
    #subtitlenya{
        font-size: 12pt;
        color: rgb(82, 82, 82);
        padding-bottom: 30px;
    }
}

@media (max-width: 360px){
    #subtitlenya{
        font-size: 12pt;
        color: rgb(82, 82, 82);
        padding-bottom: 30px;
    }
}

#registerpaspor{
    background-color: #ffffff;
}

#iconform{
    color: rgb(139, 139, 139);
}

  #nama{
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #tempat_lahir{
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #tanggal_lahir{
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #phone{
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #email{
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #no_passport{
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #date_issue{
    width: 100%;
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #date_expiry{
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #register_no{
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #issuing_office{
    font-family: 'Open Sans Condensed', sans-serif;
  }

#text_label{
    width: 70px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 9pt;
}

.link_footer{
    color: rgb(3, 123, 128);
}